import React, { useState } from 'react'
import Floating from './Floating'
import styled from '@emotion/styled'
import {
  breakpoints
} from 'utils/styles'

const Container = styled(`div`)`
  padding: 50px 0;
  @media (max-width: ${breakpoints.phablet}px) {
  	br {
  		display: none;
  	}
  }
  h1 {
  	font-family: 'Avenir Next';
  	letter-spacing: -2px;
  	font-size: 2rem;
    line-height: 60px;
    color: #222;
  	z-index: 100;
    position: relative;
    @media (min-width: ${breakpoints.tablet}px) {
    	font-size: 3rem;
    	line-height: 80px;
    }
    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 80px;
      line-height: 90px;
    }
    @media (max-width: ${breakpoints.mobile}px) {
      font-size: 1.8rem;
      line-height: 40px;
    }
  	span {
  		cursor: default;
  		padding: 0 .2em;
  	}
  }
`

export default (props) => {
	const [selected, setSelected] = useState('play')
  return (
		<Container>
			<Floating selected={selected}/>
			<h1>Taking a
				<span style={{color:selected=='play'?'rgb(252, 92, 148)' :'#adb5bd'}} onMouseEnter={()=>setSelected('play')}>playful</span>
				approach <br/>
				to bringing 
				<span style={{color:selected=='inspired'?'#03e7b0':'#adb5bd'}} onMouseEnter={()=>setSelected('inspired')}>inspired</span> 
				ideas <br/> to 
				<span style={{color:selected=='digital'?'#1502ff':'#adb5bd'}} onMouseEnter={()=>setSelected('digital')}>digital</span> 
				life.
			</h1>
		</Container>
	)
}
	
