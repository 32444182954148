import React from 'react'
import styled from '@emotion/styled'
import TweenOne from 'rc-tween-one'
import PathPlugin from 'rc-tween-one/lib/plugin/PathPlugin'

import airplane from '../../../static/images/airplane.png'
import brain from '../../../static/images/brain.png'
import computer from '../../../static/images/computer.png'
import bulb from '../../../static/images/bulb.png'
import light from '../../../static/images/light.png'

import styles from './Floating.module.scss'
import { keyframes } from '@emotion/core'
import { breakpoints } from 'utils/styles'

TweenOne.plugins.push(PathPlugin)
// const Floater = (props) => {
// 	const start = Math.floor(Math.random() * 20)
// 	const end = Math.floor(Math.random() * 20)
// 	const path = `M3.5,175V19c0,0,1-8.75,8.25-11.5S26.5,8,26.5,8l54,53.25
//       c0,0,7,8.25,14.5,0.75s51.5-52.25,51.5-52.25s9.75-7,18-2s7.75,11.5,7.75,11.5
//       v104c0,0-0.5,15.75-15.25,15.75s-15.75-15-15.75-15V68.5c0,0-0.125-9.125-6-3.25
//       s-36.25,36-36.25,36s-11.625,11.875-24-0.5S40.25,65.5,40.25,65.5
//       s-5.75-5.25-5.75,2s0,107.25,0,107.25s-0.75,13.5-14.5,13.5S3.5,175,3.5,175z`
// 	const animation = {
//       path: path,
//       repeat: -1,
//       duration: 2500,
//       yoyo: true,
//       ease: 'easeInOutQuad',
//     }
//     return (
//       <TweenOne
//         animation={animation}
//         component={Float}
//         src={props.src}
//       />
//     )
// }

const FadeIn = (props) => keyframes`
  from {
    opacity: .25;
  } to {
  	opacity: 1;
  }
`

const Container = styled.div`
	opacity: 0;
	animation: ${FadeIn} .6s 1s forwards ease;
`

class Floater extends React.Component {
  constructor(props) {
    super(props);
    this.path = `M134.640625,462.15979 C208.448304,444.842643 268.28125,328.053672 268.28125,201.304322 C268.28125,74.5549717 208.448304,-14.1573567 134.640625,3.15979048 C60.8329459,20.4769377 1,137.265909 1,264.015259 C1,390.764609 60.8329459,479.476938 134.640625,462.15979 Z`;
    this.path2 = `M377.343027,381.859046 C497.040696,388.050256 595.52457,303.316573 595.52457,206.429523 C595.52457,109.542473 497.841366,31 377.343027,31 C256.844689,31 112.465896,235.294554 77.0849303,255.188693 C20.6824332,286.902896 -27.6396953,193.221718 43.8114067,177.892857 C156.104117,153.801985 257.645359,375.667835 377.343027,381.859046 Z`
    this.path3 = `M275,466 C423.564598,466 544,384.739682 544,284.5 C544,188.257755 432.978703,109.511309 292.633671,103.383739 C286.804418,103.12923 280.924576,103 275,103 C126.435402,103 6,184.260318 6,284.5 C6,384.739682 126.435402,466 275,466 Z`
    this.pathBottom = 'M384.055848,355.40061 C433.70725,355.40061 525.181433,403.431762 578.296108,403.3956 C580.234176,403.395641 654.464977,408.888858 698.047434,382.089233 C754.377571,347.450833 782.99976,276.299231 782.99976,251.317945 C782.99976,135.700975 580.724472,0.860009875 374.465397,0.860009875 C168.206322,0.860009875 0.999760381,94.5860826 0.999760381,210.203052 C0.999760381,284.588767 42.2411899,403.3956 168.580358,403.3956 C222.325013,403.3956 271.373074,355.40061 384.055848,355.40061 Z'

    this.animation = {
      path: this.path,
      repeat: -1,
      duration: 3700,
      ease: 'linear'
    };
    this.animation2 = {
      path: this.path2,
      repeat: -1,
      duration: 4300,
      ease: 'linear'
    };
    this.animation3 = {
      path: this.path3,
      repeat: -1,
      duration: 3000,
      ease: 'linear'
    };
    this.animation4 = {
      path: this.path3,
      repeat: -1,
      duration: 4200,
      ease: 'linear'
    };
    this.bottom = {
      path: this.pathBottom,
      repeat: -1,
      duration: 5500,
      ease: 'linear'
    };
  }
  render() {
  	return (
  			<Container>
		      <TweenOne
		        animation={this.animation}
		        component={Float}
		        src={this.props.src}
		      />
		      <TweenOne
		        animation={this.animation2}
		        component={Float2}
		        src={this.props.src}
		      />
		      <TweenOne
		        animation={this.animation3}
		        component={Float3}
		        src={this.props.src}
		      />
		      <TweenOne
		        animation={this.animation3}
		        component={Float4}
		        src={this.props.src}
		      />
		      <TweenOne
		        animation={this.animation4}
		        component={Float5}
		        src={this.props.src}
		      />
		      <TweenOne
		        animation={this.animation3}
		        component={Float6}
		        src={this.props.src}
		      />
		      <TweenOne
		        animation={this.animation2}
		        component={Float7}
		        src={this.props.src}
		      />
		      <TweenOne
		        animation={this.bottom}
		        component={Float8}
		        src={this.props.src}
		      />
	      	</Container>
  		)
  }
}

const Float = styled('img')`
	position: absolute;
	width: 90px;
	z-index: -1;
	top: 0px;
	left: 50%;
`	

const Float2 = styled('img')`
	top: 1000px;
	/*right: 800px;*/
	position: absolute;
	width: 90px;
	z-index: -1;
`	
const Float3 = styled('img')`
	bottom: 100px;
	left: 5%;
	position: absolute;
	width: 90px;
	z-index: -1;
`	

const Float4 = styled('img')`
	top: 1900px;
	left: 15%;
	position: absolute;
	width: 90px;
	z-index: -1;

`	

const Float5 = styled('img')`
	top: 2300px;
	left: 15%;
	position: absolute;
	width: 90px;
	z-index: -1;
`	

const Float6 = styled('img')`
	top: 2800px;
	left: 35%;
	position: absolute;
	width: 90px;
	z-index: -1;
`	

const Float7 = styled('img')`
	top: 3000px;
	left: 15%;
	position: absolute;
	width: 90px;
	z-index: -1;
`	

const Float8 = styled('img')`
	top: 3650px;
	left: 8%;
	position: absolute;
	width: 90px;
	z-index: -1;
	@media (max-width: ${breakpoints.tablet}px) {
		top: 3400px;
		left:0
	}
`	

export default ({selected}) => (
	<div className={styles.container}>
		<div className={styles.wrapper}>
			<div className={styles.background}>
				<Floater src={selected=='inspired'?light:selected=='digital'?computer:airplane} delay={1000}/>
			</div>
		</div>
	</div>
)