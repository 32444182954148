import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Box from 'components/Box'
import { Welcome } from 'components/HomePage'
import Work from 'components/Work'
import Fragment from 'react-dom-fragment'

const IndexPage = ({data}) => (
	<>
	   	<Welcome/>
	   	<Work/>
   	</>
)


export default ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO />
      <IndexPage data={data} />
    </Layout>
  )
}

