import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { useInView } from 'react-intersection-observer'

import styles from './Work.module.scss'
import { WORK_DATA } from './data.js'

const simpleEntry = keyframes`
  from {
    opacity: 0;
    transform: translateY(80px);
  } to {
    opacity: 1;
  }
`

const Container = styled.div`
  opacity: 0;
  display: ${props=> props.inView ? 'block' : 'none' };
  animation: ${simpleEntry} .5s .1s forwards ease;
  width: 100%
`

const WorkItem = ({suggestion}) => {
  const [ref, inView, entry] = useInView({
    triggerOnce: true,
    threshold: .6,
  })
  return (
      <div className={styles.gridWrapper} key={suggestion.title} ref={ref}>
        <div className={styles.gridColumn}>
          <Container inView={inView}>
          <div className={styles.item}>
            <a
              href={suggestion.link}
              target="_blank"
              className={styles.suggestionItem}
            >
              <div className={styles.overlayContainer}>
                <img src={suggestion.image} className={styles.suggestionImg} />
                <div className={styles.overlay}></div>
              </div>
              <h4 className={styles.suggestionTitle}>{suggestion.title}</h4>
              <div className={styles.suggestionDescription}>
                {suggestion.description}
              </div>
              <div className={styles.visitLink}>Visit site 
                <span>{' → '}</span>
              </div>
            </a>
          </div>
           </Container>
        </div>
       
      </div>
    
  )
}

export default function() {
  
  return (
    <div className={styles.suggestionContainer}>
      <div className={styles.sectionTitle}>Selected Work</div>
        {WORK_DATA.map(suggestion => (
          <WorkItem suggestion={suggestion} />
        ))}
    </div>
  )
}






