import borrowed from '../../../static/images/borrowed.gif'
import sprout from '../../../static/images/sprout.jpg'
import bloomed from '../../../static/images/bloomed.jpg'
import thebit from '../../../static/images/thebit.jpg'
import mylkguys from '../../../static/images/mylkguys.jpg'
import itty from '../../../static/images/itty.png'


export const WORK_DATA = [
	{
		image: itty,
		title: 'itty',
		link: 'https://itty.netlify.app',
		description: 'Complete Product Development · Branding · Web development · UX Design · Art Direction · Strategy'
	},
	{
		image: bloomed,
		title: 'Bloomed',
		link: 'https://bloomed.org',
		description: 'Branding · Web development · Strategy · Copy'
	},	
	{
		image: borrowed,
		title: 'Borrowed',
		link: 'https://borrowed.me',
		description: 'Complete Product Development · Branding · Web development · UX Design'
	},
	{
		image: mylkguys,
		title: 'Mylk Guys',
		link: 'https://mylkguys.com',
		description: 'Web Development · Web Optimizations · Flexible CMS systems'
	},
	{
		image: sprout,
		title: 'Sprout',
		// link: 'https://sprout.delivery',
		description: 'Branding · Web development · Ephemera'
	},	
	
	{
		image: thebit,
		title: 'TheBit',
		description: 'Complete Product Development · Branding · Web development · Strategy · Copy'
	},
]





